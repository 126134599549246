// vibes.js

let vibeData = []; // Store vibe data globally to access it when markers are clicked

// Function to filter liked/unliked markers by a specified date range and update the map
function filterMarkersByDateRange(rangeValue) {
    $("#rangeValue").text(rangeValue);

    const timestampLimit = new Date();
    timestampLimit.setHours(timestampLimit.getHours() - rangeValue);

    const formattedTimestamp = timestampLimit.toISOString().slice(0, 19).replace('T', ' ');

    $.ajax({
        type: "POST",
        url: "updateListAndMap.php",
        data: { timestampLimit: formattedTimestamp },
        dataType: "json",
        success: function (response) {
            if (response && Array.isArray(response)) {
                console.log("Fetched markers:", response); // Debugging line
                vibeData = response; // Store data globally
                updateMapLocations(response);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        error: function (xhr, status, error) {
            console.error("Error fetching locations:", error);
        }
    });
}

// Function to update map locations based on fetched data and timestamp limit
function updateMapLocations(data) {
    clearLikedUnlikedMarkers();

    // Add markers for each vibe
    data.forEach(item => {
        createAdvancedMarker({
            type: 'vibe',
            latitude: item.latitude,
            longitude: item.longitude,
            liked: item.liked,
            id: item.id,
            name: item.name || `Vibe ${item.id}` // Default name if none provided
        });
    });
}

// Function to clear only liked/unliked markers from the map
function clearLikedUnlikedMarkers() {
    markers = markers.filter(marker => {
        if (!marker.content) {
            console.warn("Marker content is undefined");
            return true;
        }

        const iconElement = marker.content.querySelector('img');
        if (!iconElement) {
            return true;
        }

        const iconUrl = iconElement.src;
        const isLikedMarker = iconUrl.includes('liked-marker.svg');
        const isUnlikedMarker = iconUrl.includes('unliked-marker.svg');

        if (isLikedMarker || isUnlikedMarker) {
            marker.setMap(null);
            return false;
        }
        return true;
    });
}

// Function to display vibe details in the bottom sheet
function showVibeDetails(vibeId) {
    const vibe = vibeData.find(v => v.id === vibeId); // Find the vibe from stored data
    if (vibe) {
        const detailsContent = generateDetailsContent(vibe);
        $('#vibeDetails').html(detailsContent);
        // setIsSheetShown($('#vibeSheet'), true);
    } else {
        console.error("Vibe not found for ID:", vibeId);
    }
}

// Function to generate HTML content for the bottom sheet
function generateDetailsContent(data) {
    return `
        <strong>Name:</strong> ${data.name || 'Not available'}<br/>
        <strong>Marker ID:</strong> ${data.id || 'Not available'}<br/>
        <strong>Latitude:</strong> ${data.latitude || 'Not available'}<br/>
        <strong>Longitude:</strong> ${data.longitude || 'Not available'}<br/>
        <strong>Liked:</strong> ${data.liked !== null ? (data.liked ? 'Yes' : 'No') : 'No feedback'}<br/>
        ${data.detailsLink ? `<a href="${data.detailsLink}" target="_blank">More details</a>` : ''}
    `;
}

//perhaps by throttling or debouncing the AJAX calls.
//Ensure that the place_id is present in the metadata for each marker.
// You might need to adjust the way vibe counts are displayed based on how the DOM elements are structured.
function fetchVibes(placeId) {
    console.log('Fetching vibes for place ID:', placeId); // Debugging line

    $.ajax({
        type: 'GET',
        url: 'get_vibe.php', // Endpoint to get vibe data
        data: { place_id: placeId },
        dataType: 'json',
        success: function (response) {
            console.log('Vibe data fetched:', response); // Debugging line

            if (response.success) {
                const likes = response.likes_count;
                const dislikes = response.dislikes_count;

                // Update the HTML with likes and dislikes
                $(`#like-count-${placeId}`).text(`Likes: ${likes}`);
                $(`#dislike-count-${placeId}`).text(`Dislikes: ${dislikes}`);
            } else {
                console.error('Failed to fetch vibe data:', response.error_message);
            }
        },
        error: function (xhr, status, error) {
            console.error('Error fetching vibe data:', error);
            console.error('XHR:', xhr); // Log the full XHR object for more details
        }
    });
}

// Create a debounced version of fetchVibesForVisibleMarkers
// const debouncedFetchVibes = debounce(fetchVibesForVisibleVenueMarkers, 5000);
const debouncedFetchVibes = debounce(fetchVibesAndEventsForVisibleVenueMarkers, 5000);

function fetchVibesAndEventsForVisibleVenueMarkers() {
    return new Promise((resolve, reject) => {
        // Get all visible venue markers
        const visibleVenueMarkers = markers.filter(marker => {
            return marker.metadata && marker.metadata.type === 'venue' && map.getBounds().contains(marker.position);
        });

        // Extract place_ids for all visible venue markers
        const placeIds = visibleVenueMarkers.map(marker => marker.metadata.place_id);
        console.log('Place IDs being sent to get_vibe.php and get_events.php:', placeIds);

        // Show loader while fetching data
        $('.fetching-vibes-loader').addClass('show');

        if (placeIds.length > 0) {
            // Create Promises for both vibes and events
            const vibesPromise = new Promise((vibesResolve, vibesReject) => {
                // Fetch vibes
                $.ajax({
                    type: 'POST',
                    url: 'get_vibe.php',
                    data: { place_ids: JSON.stringify(placeIds) },
                    dataType: 'json',
                    success: function (vibeResponse) {
                        console.log('Vibe data fetched:', vibeResponse);

                        // Process vibes for the visible venue markers
                        visibleVenueMarkers.forEach(marker => {
                            const vibes = vibeResponse.vibe_counts[marker.metadata.place_id];
                            if (vibes) {
                                const score = vibes.likes_count - vibes.dislikes_count;
                                marker.metadata.vibeScore = score;

                                // Update the class of the marker based on vibe score
                                let markerClass = marker.content.className.replace(/\bpositive\b|\bnegative\b|\bneutral\b/g, '');
                                if (score > 0) {
                                    markerClass += ' positive';
                                } else if (score < 0) {
                                    markerClass += ' negative';
                                } else {
                                    markerClass += ' neutral';
                                }
                                marker.content.className = markerClass.trim();
                            }
                        });
                        vibesResolve(); // Resolve when done
                    },
                    error: function (xhr, status, error) {
                        console.error('Error fetching vibes:', error);
                        console.log('Response:', xhr.responseText); // Log response for debugging
                        vibesReject(error); // Reject in case of error
                    },
                    complete: function () {
                        $('.fetching-vibes-loader').removeClass('show');
                    }
                });
            });

            const eventsPromise = new Promise((eventsResolve, eventsReject) => {
                // Fetch events
                $.ajax({
                    type: 'GET',
                    url: 'get_events.php',
                    data: { place_ids: JSON.stringify(placeIds) },
                    dataType: 'json',
                    success: function (eventResponse) {
                        //console.log("Events received:", eventResponse.events.map(e => e.place_id));
                        //console.log("Visible venue markers:", visibleVenueMarkers.map(m => m.metadata.place_id));

                        // Process events for the visible venue markers
                        populateEventsList(eventResponse.events);

                        // Process events for the visible venue markers
                        visibleVenueMarkers.forEach(marker => {
                            const event = eventResponse.events.find(event => event.place_id === marker.metadata.place_id);
                            if (event) {
                                // Assign event data to the marker
                                marker.metadata.event = event;

                                // Add the 'event' class to the venue marker
                                marker.content.className += ' event';  // Add the 'event' class

                                // Check if there's no proper date, but a raw date description exists
                                // if (!event.date_from && event.raw_date_description) {
                                //     console.log(`Raw date description for event: ${event.raw_date_description}`);
                                // }
                            }
                        });
                        eventsResolve(); // Resolve when done
                    },
                    error: function (xhr, status, error) {
                        console.error('Error fetching events:', error);

                        // Log response and handle JSON errors
                        if (!xhr.responseText || xhr.responseText.trim() === "") {
                            console.error('Empty response from server');
                        } else {
                            try {
                                const data = JSON.parse(xhr.responseText);
                                console.log('Parsed data:', data);
                            } catch (jsonError) {
                                console.error('Error parsing JSON:', jsonError);
                            }
                        }

                        eventsReject(error); // Reject in case of error
                    },
                    complete: function () {
                        $('.fetching-vibes-loader').removeClass('show');
                    }
                });
            });

            // Run both AJAX calls in parallel and resolve the main Promise
            Promise.all([vibesPromise, eventsPromise])
                .then(() => {
                    resolve(); // Resolve the main function when both AJAX calls complete
                })
                .catch(error => {
                    reject(error); // Reject if any of the AJAX calls fail
                });
        } else {
            console.log('No visible venue markers found to fetch vibes or events for.');
            resolve(); // Resolve even if no markers are found
        }
    });
}











