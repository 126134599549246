let events = [];


// Fetch event details and display them in the bottom sheet
// function showEventDetails(event) {
//     const detailsContent = `
//         <strong>Event Name:</strong> ${event.name || 'Not available'}<br/>
//         <strong>Date:</strong> ${event.dateFrom || 'Not available'}<br/> ${event.dateTo} ${event.timeTo}
//         <strong>Location:</strong> ${event.location || 'Not available'}<br/>
//         <strong>Description:</strong> ${event.description || 'No description'}<br/>
//         ${event.detailsLink ? `<a href="${event.detailsLink}" target="_blank">More details</a>` : ''}
//     `;
//     $('#eventDetails').html(detailsContent);
//     setIsSheetShown($('#eventSheet'), true); // Show the event sheet
// }




// show global events in a list
function populateEventsList(events) {
    const eventsUl = $('#eventsUl');
    eventsUl.empty(); // Clear the list before populating

    if (!events || events.length === 0) {
        console.warn('No events to display.');
        eventsUl.append('<div class="no-results"><p>No events found</p></div>');
        return;
    }

    // Create a map of place_ids to venue names
    const venueNameMap = {};
    nearbyBars.forEach(bar => {
        venueNameMap[bar.place_id] = bar.name;
    });

    // Create a map to store the next upcoming event for each place_id
    const nextEventsByVenue = {};

    events.forEach(event => {
        const today = new Date();
        let eventDate = event.date_from ? new Date(event.date_from) : null;

        // If the event has a specific date, use it; otherwise, it's recurring or descriptive
        if (eventDate && eventDate >= today) {
            // If there's already an event for this venue, compare dates
            if (!nextEventsByVenue[event.place_id] || new Date(nextEventsByVenue[event.place_id].date_from) > eventDate) {
                nextEventsByVenue[event.place_id] = event;
            }
        } else if (event.is_recurring || event.raw_date_description) {
            // For recurring or descriptive events, prioritize them if no other future events are found
            if (!nextEventsByVenue[event.place_id]) {
                nextEventsByVenue[event.place_id] = event;
            }
        }
    });

    // Now populate the event list with the next event from each venue
    Object.values(nextEventsByVenue).forEach(event => {
        const venueName = venueNameMap[event.place_id] || 'Unknown Venue';
        const formattedDate = event.date_from
            ? (event.date_to
                ? `${formatDate(event.date_from)} - ${formatDate(event.date_to)}`
                : formatDate(event.date_from))
            : (event.raw_date_description || 'Date not available');

        const eventItem = `

                <li class="event-item">
                    ${event.event_image ? `<figure class="item-img"><img src="${event.event_image}" alt="Event Image"></figure>` : ''}
                    <div class="item-content">
                        <div class="event-date">${formattedDate}</div>
                        <h4 class="item-name">${event.event_name}</h4>
                        <div class="venue-name">${venueName}</div>
                        ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
                        ${event.link ? `<div class="event-link"><a href="${event.link}" target="_blank">More details</a></div>` : ''}
                    </div>
                </li>

        `;
        eventsUl.append(eventItem);
    });
}

// Helper function to format dates (e.g., "2024-09-13" -> "13 Sep 2024")
function formatDate(dateString) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
}

// Helper function to fetch events for the venue and populate the event info
function fetchEventsForVenue(place_id, eventInfoElement) {
    $.ajax({
        type: 'GET',
        url: 'get_events.php',
        data: { place_ids: JSON.stringify([place_id]) },
        dataType: 'json',
        success: function (eventResponse) {
            try {
                if (eventResponse && eventResponse.events && Array.isArray(eventResponse.events) && eventResponse.events.length > 0) {
                    let eventContent = '';

                    eventResponse.events.forEach(event => {
                        const formattedDate = event.date_from
                            ? (event.date_to
                                ? `${formatDate(event.date_from)} - ${formatDate(event.date_to)}`
                                : formatDate(event.date_from))
                            : (event.raw_date_description || 'Date not available');

                        eventContent += `
                            <div class="card-item">
                                ${event.event_image ? `<figure class="item-img"><img src="${event.event_image}" alt="Event Image"></figure>` : ''}
                                <div class="item-content">
                                    <h4 class="item-name">${event.event_name || 'Event Name Not Available'}</h4>
                                    <div class="event-date">${formattedDate}</div>
                                    ${event.time_from && event.time_to ? `<div class="event-time">${event.time_from} - ${event.time_to}</div>` : ''}
                                    ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
                                    ${event.link ? `<div class="event-link"><a href="${event.link}" target="_blank">More details</a></div>` : ''}
                                </div>
                            </div>
                        `;
                    });

                    eventInfoElement.innerHTML = eventContent;
                    eventInfoElement.style.display = 'flex';
                } else {
                    eventInfoElement.innerHTML = '<div class="no-results"><p>No events found</p></div>';
                    eventInfoElement.style.display = 'flex';
                }
            } catch (error) {
                console.error('Error processing event data:', error);
                eventInfoElement.innerHTML = '<p>Error loading events</p>';
                eventInfoElement.style.display = 'flex';
            }
        },
        error: function (xhr, status, error) {
            console.error('Error fetching event details:', error);
            eventInfoElement.innerHTML = '<p>Error loading events</p>';
            eventInfoElement.style.display = 'flex';
        }
    });
}

// Ensure formatDate function handles invalid dates
function formatDate(dateString) {
    if (!dateString) return 'Date not available';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}


