// map.js
// Global variables
var map;
var markers = []; // Array to hold all markers
let userLocation = null; // User's location
// let userLocation = { lat: 50.9123814, lng: -1.4049011 }; //orange rooms
//let userLocation = { lat: 51.0631103, lng: -1.3169268 };
//let userLocation = { lat: 51.5011343, lng: -0.1118477 }; //waterloo

// uncomment line 36ish when done debugging
let userMarker = null; // Marker for user's location
let nearbyBars = [];

// Default location (used if user denies location access)
//const defaultLocation = { lat: 51.0606892, lng: -1.3131538 }; // winch
//const defaultLocation = { lat: 51.5191715, lng: -0.0963904 }; // london
const defaultLocation = { lat: 51.5011343, lng: -0.1118477 }; // london waterloo


// Handle the results of the nearby search
// Define the desired number of results as a constant
const DESIRED_RESULTS = 20; // Change this value to adjust the number of results

const API_KEY = 'AIzaSyBgWd0jY0IQv41LhPrk_Qg5M65wS0RKhUo';
const PHOTO_LIMIT = 9;

// Function to update the user's location marker on the map
function updateUserMarker(location) {
    if (userMarker) {
        // Update the existing marker's position
        userMarker.position = new google.maps.LatLng(location.lat, location.lng);
        userMarker.map = map; // Ensure marker is on the map
        //console.log('Marker updated to new position:', location);
    } else {
        // Create a new marker if none exists
        addUserLocationMarker();
    }
}


// Initialize the Google Map
function initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLocation,
        zoom: 14,
        fullscreenControl: false,
        mapId: 'f6b957fb3fe1b7bb',
        zoomControl: false,
        mapTypeControl: false,
        cameraControl: false,
        streetViewControl: false,
        rotateControl: false,
        scaleControl: false,
        keyboardShortcuts: false,
    });





    // Initialize the PlacesService
    placesService = new google.maps.places.PlacesService(map);
    //   console.log('PlacesService initialized');






    initCenterControl();

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    // lat: 51.5011343, 
                    // lng: -0.1118477 
                };
                map.setCenter(userLocation);
                addUserLocationMarker();
                fetchBarsNearUser();

            },
            () => {
                handleLocationError(true, defaultLocation);
            }
        );
    } else {
        handleLocationError(false, defaultLocation);
    }

    google.maps.event.addListenerOnce(map, 'idle', () => {
        loadVibeMarkers();
        fetchVibesAndEventsForVisibleVenueMarkers();
        debug();
    });



    // Use the debounced version for fetching vibes when the user interacts with the map
    // steve comment
    map.addListener('idle', debouncedFetchVibes);
}



// Initialize the custom center control functionality
function initCenterControl() {
    const centerControlElement = document.getElementById('center-control');

    centerControlElement.addEventListener('click', () => {
        if (userLocation) {
            map.setCenter(userLocation);
            map.setZoom(14); // Optional: Set a default zoom level when centering
        } else {
            map.setCenter(defaultLocation);
            map.setZoom(14);
        }
    });
}

// Add a marker for the user's location using the AdvancedMarkerElement
function addUserLocationMarker() {
    if (userLocation) {
        if (userMarker) {
            userMarker.map = null; // Remove existing marker
        }

        // Create an advanced marker for the user's location
        const content = document.createElement('div');
        content.innerHTML = `<div class="users-location"></div>`;

        userMarker = new google.maps.marker.AdvancedMarkerElement({
            position: userLocation,
            map: map,
            content: content
        });

        markers.push(userMarker);
    }
}

// Error handling for geolocation
function handleLocationError(browserHasGeolocation, pos) {
    // Set userLocation to defaultLocation if geolocation fails
    userLocation = pos;
    map.setCenter(userLocation);

    // Load markers even if geolocation fails
    fetchBarsNearUser();
    loadAllEvents();
    loadVibeMarkers();

    const infoWindow = new google.maps.InfoWindow({
        position: pos,
        content: browserHasGeolocation
            ? "Error: The Geolocation service failed. Showing default location."
            : "Error: Your browser doesn't support geolocation. Showing default location.",
    });
    //infoWindow.open(map);
}

// Load vibe markers
function loadVibeMarkers() {
    if (typeof filterMarkersByDateRange === 'function') {
        // Filter for the last 2 hours or as required
        filterMarkersByDateRange(2);
    } else {
        console.error("Vibe loading function is not defined.");
    }
}

// Fetch nearby bars and nightclubs using Google Places API
function fetchBarsNearUser() {
    if (!userLocation) {
        console.error('User location is not available.');
        return;
    }

    const lat = userLocation.lat;
    const lng = userLocation.lng;

    if (!lat || !lng) {
        console.error('Invalid user location:', userLocation);
        return;
    }

    const cacheKey = `nearbyBars_${lat.toFixed(4)}_${lng.toFixed(4)}`;
    const cachedResults = localStorage.getItem(cacheKey);

    if (cachedResults) {
        const { timestamp, results } = JSON.parse(cachedResults);

        if (isCacheValid(timestamp)) {
            console.log('Using cached nearby bars results');
            handleSearchResults(results);
            return;
        }
    }

    const service = new google.maps.places.PlacesService(map);
    const radiusOptions = [700, 2000, 5000];
    let currentRadiusIndex = 0;
    let totalResults = [];
    let uniquePlaceIds = new Set();

    function searchWithRadius() {
        if (currentRadiusIndex >= radiusOptions.length) {
            console.log('Search completed. Total unique results:', totalResults.length);

            localStorage.setItem(cacheKey, JSON.stringify({
                timestamp: new Date().getTime(),
                results: totalResults
            }));

            handleSearchResults(totalResults);
            return;
        }

        const request = {
            location: new google.maps.LatLng(lat, lng),
            radius: radiusOptions[currentRadiusIndex],
            type: 'bar',
            keyword: 'pub',
        };

        service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                console.log(`Found ${results.length} results at radius ${radiusOptions[currentRadiusIndex]}m`);

                const newUniqueResults = results.filter(result => {
                    if (!uniquePlaceIds.has(result.place_id)) {
                        uniquePlaceIds.add(result.place_id);
                        return true;
                    }
                    return false;
                });

                totalResults = totalResults.concat(newUniqueResults);

                if (totalResults.length >= DESIRED_RESULTS) {
                    console.log(`Sufficient unique results found (${totalResults.length}). Stopping search.`);

                    localStorage.setItem(cacheKey, JSON.stringify({
                        timestamp: new Date().getTime(),
                        results: totalResults
                    }));

                    handleSearchResults(totalResults);
                } else {
                    currentRadiusIndex++;
                    searchWithRadius();
                }
            } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                console.log(`No results at radius ${radiusOptions[currentRadiusIndex]}m. Expanding search.`);
                currentRadiusIndex++;
                searchWithRadius();
            } else {
                console.error('Error in Places API request:', status);

                if (totalResults.length > 0) {
                    localStorage.setItem(cacheKey, JSON.stringify({
                        timestamp: new Date().getTime(),
                        results: totalResults
                    }));
                }

                handleSearchResults(totalResults);
            }
        });
    }

    searchWithRadius();
}



function fetchAndSaveVenueDetails(feature, service) {
    service.getDetails({ placeId: feature.place_id }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const venueDetails = {
                place_id: feature.place_id,
                name: place.name,
                address: place.formatted_address || 'Address not available',
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                photo_urls: place.photos ? place.photos.slice(0, PHOTO_LIMIT).map(photo => photo.getUrl({ maxWidth: 400 })) : [],
                opening_hours: place.opening_hours ? place.opening_hours.weekday_text : null,
                google_rating: place.rating || null
            };
            saveVenueToDatabase(venueDetails);
        } else {
            console.error('Failed to fetch venue details from Google API:', status);
        }
    });
}

// Helper function to get lat/lng, handling both function and property cases
function getLatLng(location) {
    if (!location) {
        console.error('Invalid location object');
        return null;
    }
    return {
        lat: typeof location.lat === 'function' ? location.lat() : location.lat,
        lng: typeof location.lng === 'function' ? location.lng() : location.lng
    };
}

// map.js
// (Earlier parts of the file remain unchanged)
function createVenueListItem(feature, venueData = null) {
    console.log('Creating venue list item with data:', {
        feature: feature,
        venueData: venueData,
        hasOpeningHours: !!feature.opening_hours,
        openingHoursType: feature.opening_hours ? typeof feature.opening_hours : 'none'
    });

    const barsUl = document.getElementById('barsUl');
    const li = document.createElement('li');
    li.className = 'venue';

    const featureLocation = getLatLng(feature.geometry.location);
    if (featureLocation) {
        li.dataset.lat = featureLocation.lat;
        li.dataset.lng = featureLocation.lng;
    }

    li.dataset.placeId = feature.place_id;

    // Initially set a loading placeholder
    let photoUrl = 'img/loading.svg';

    li.innerHTML = `
        <figure class="item-img">
            <img src="${photoUrl}" alt="${feature.name}" data-place-id="${feature.place_id}">
        </figure>
        <div class="item-content">
            <h4 class="item-name">${feature.name}</h4>
            <div class="venue-particulars">
                <span class="open-now-status">Checking status...</span>
                <span class="distance-display">${feature.distance.toFixed(2)} km</span>
            </div>
        </div>
    `;

    li.addEventListener('click', handleVenueListItemClick);
    barsUl.appendChild(li);

    const statusElement = li.querySelector('.open-now-status');

    // Handle open status
    if (venueData) {
        // For cached venues
        console.log('Updating venue status with cached data:', venueData);
        OpeningHoursHandler.updateVenueOpenStatus(li, {
            opening_hours: venueData.opening_hours,
            opening_hours_periods: venueData.opening_hours_periods
        });
    } else {
        // For uncached venues, fetch detailed data immediately
        const service = new google.maps.places.PlacesService(map);
        service.getDetails({
            placeId: feature.place_id,
            fields: ['opening_hours']
        }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place.opening_hours) {
                try {
                    if (place.opening_hours.periods) {
                        // Use the same logic as the venue sheet
                        const isOpen = checkOpenStatusFromPeriods(place.opening_hours.periods);
                        statusElement.textContent = isOpen ? 'Open Now' : 'Closed';
                        statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
                        li.dataset.openNow = isOpen.toString();
                    } else {
                        // Fallback to isOpen() if periods not available
                        const isOpen = place.opening_hours.isOpen();
                        statusElement.textContent = isOpen ? 'Open Now' : 'Closed';
                        statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
                        li.dataset.openNow = isOpen.toString();
                    }
                } catch (e) {
                    console.error('Error checking open status:', e);
                    statusElement.textContent = 'Status unknown';
                    statusElement.className = 'open-now-status unknown';
                    li.dataset.openNow = 'unknown';
                }
            } else {
                statusElement.textContent = 'Status unknown';
                statusElement.className = 'open-now-status unknown';
                li.dataset.openNow = 'unknown';
            }
        });
    }

    // Fetch the photo URL
    fetchVenuePhoto(feature.place_id, li.querySelector('img'), venueData);
}


function fetchVenuePhoto(placeId, imgElement, venueData = null) {
    // Check if we have cached data
    if (venueData && venueData.photo_urls) {
        let photoUrls = venueData.photo_urls;
        try {
            if (typeof photoUrls === 'string') {
                photoUrls = JSON.parse(photoUrls);
            }
            if (Array.isArray(photoUrls) && photoUrls.length > 0) {
                imgElement.src = processPhotoUrl(photoUrls[0]);
                return;
            }
        } catch (error) {
            console.error('Error processing cached photo URLs:', error);
        }
    }

    // If no cached photos, fetch from Google Places API
    const service = new google.maps.places.PlacesService(map);
    service.getDetails({
        placeId: placeId,
        fields: ['photos']
    }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK &&
            place.photos &&
            place.photos.length > 0) {
            imgElement.src = place.photos[0].getUrl({ maxWidth: 400 });
        } else {
            imgElement.src = 'img/placeholder.png';
        }
    });
}

function handleSearchResults(results) {
    nearbyBars = results; // Store results for later use
    const barsUl = document.getElementById('barsUl');
    barsUl.innerHTML = ''; // Clear any previous bars

    if (results.length === 0) {
        barsUl.innerHTML = '<li>No venues found nearby. Try expanding your search.</li>';
        console.log('No results found');
        return;
    }

    // Calculate distances for all venues first
    results.forEach(feature => {
        if (!feature.geometry?.location) {
            console.error('Invalid geometry for feature:', feature);
            feature.distance = Infinity;
            return;
        }
        const from = new google.maps.LatLng(userLocation.lat, userLocation.lng);
        const featureLocation = getLatLng(feature.geometry.location);
        if (featureLocation) {
            const to = new google.maps.LatLng(featureLocation.lat, featureLocation.lng);
            feature.distance = google.maps.geometry.spherical.computeDistanceBetween(from, to) / 1000; // Convert to kilometers
        } else {
            console.error('Invalid location for feature:', feature);
            feature.distance = Infinity;
        }
    });

    // Sort all results by distance
    results.sort((a, b) => a.distance - b.distance);

    // Create an array to hold all venue promises
    const venuePromises = results.slice(0, DESIRED_RESULTS).map(feature => {
        return new Promise((resolve) => {
            $.ajax({
                type: 'GET',
                url: 'get_venue_details.php',
                data: {
                    place_id: feature.place_id,
                    cache_duration: getCacheDurationInSeconds()
                },
                dataType: 'json',
                success: function (response) {
                    // Return an object containing both the feature and the response
                    resolve({
                        feature: feature,
                        cachedData: response.success ? response.data : null
                    });
                },
                error: function () {
                    resolve({
                        feature: feature,
                        cachedData: null
                    });
                }
            });
        });
    });

    // Wait for all venue data to be fetched before creating UI elements
    Promise.all(venuePromises).then(venueResults => {
        // Sort again by distance to ensure order
        venueResults.sort((a, b) => a.feature.distance - b.feature.distance);

        // Now create UI elements in the correct order
        venueResults.forEach(({ feature, cachedData }) => {
            if (cachedData && cachedData.needs_refresh) {
                fetchAndSaveVenueDetails(feature, new google.maps.places.PlacesService(map));
            }
            createVenueListItem(feature, cachedData);
            createVenueMarker(feature, cachedData);
        });

        console.log(`Displayed ${venueResults.length} out of ${results.length} total unique results`);
        initializeOpenNowFilter();
        updateNearbyBarsDropdown();
    });
}

function createVenueMarker(feature, venueData = null) {
    const featureLocation = getLatLng(feature.geometry.location);
    if (featureLocation) {
        createAdvancedMarker({
            type: 'venue',
            latitude: featureLocation.lat,
            longitude: featureLocation.lng,
            liked: null, // No like status for new venues
            place_id: feature.place_id,
            name: feature.name,
            vibeScore: venueData ? venueData.vibeScore : 0,
            likes: venueData ? venueData.likes : 0,
            dislikes: venueData ? venueData.dislikes : 0
        });
    } else {
        console.error('Invalid location for feature:', feature);
    }
}

function updateOpenStatus(element, venueData) {
    const statusElement = element.querySelector('.open-now-status');
    if (!statusElement) return;

    console.log('Updating open status for venue:', venueData);

    // Function to update the UI
    const updateUI = (isOpen) => {
        if (typeof isOpen === 'boolean') {
            statusElement.textContent = isOpen ? 'Open Now' : 'Closed';
            statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
            if (element.dataset) {
                element.dataset.openNow = isOpen.toString();
            }
            console.log('Set status to:', statusElement.textContent);
        }
    };

    // For newly fetched venues or when we want to force a fresh check
    const checkWithGoogleAPI = () => {
        if (!venueData.place_id) {
            console.error('No place_id available for Google API check');
            return;
        }

        const service = new google.maps.places.PlacesService(map);
        service.getDetails({
            placeId: venueData.place_id,
            fields: ['opening_hours']
        }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place.opening_hours) {
                const isOpen = place.opening_hours.isOpen();
                updateUI(isOpen);
            }
        });
    };

    // If we have a cached venue with opening hours string
    if (venueData.opening_hours && typeof venueData.opening_hours === 'string') {
        // For cached venues, first check with our PHP implementation
        $.ajax({
            type: 'POST',
            url: 'check_opening_hours.php',
            data: {
                opening_hours: venueData.opening_hours
            },
            success: function (response) {
                try {
                    const result = JSON.parse(response);
                    if (result.success) {
                        updateUI(result.is_open);
                    } else {
                        checkWithGoogleAPI();
                    }
                } catch (e) {
                    console.error('Error parsing opening hours response:', e);
                    checkWithGoogleAPI();
                }
            },
            error: function () {
                checkWithGoogleAPI();
            }
        });
    } else if (venueData.place_id) {
        // If no cached opening hours but we have a place_id, use Google API
        checkWithGoogleAPI();
    }
}





function initializeOpenNowFilter() {
    const filterCheckbox = document.getElementById('open-now-filter');
    if (!filterCheckbox) {
        console.error("Could not find the open-now-filter checkbox");
        return;
    }

    filterCheckbox.addEventListener('change', function () {
        const venues = document.querySelectorAll('.venue');
        venues.forEach(venue => {
            if (this.checked) {
                venue.style.display = venue.dataset.openNow === 'true' ? '' : 'none';
            } else {
                venue.style.display = '';
            }
        });
    });
}


// New function to handle list item clicks
function handleVenueListItemClick(event) {
    const listItem = event.currentTarget;
    const placeId = listItem.dataset.placeId;

    if (placeId) {
        // Check if the venue exists in the database
        $.ajax({
            type: 'GET',
            url: 'get_venue_details.php',
            data: {
                place_id: placeId,
                cache_duration: getCacheDurationInSeconds()
            },
            dataType: 'json',
            success: function (response) {
                if (response.success && response.data) {
                    console.log('Venue found in DB:', response.data);
                    if (response.data.needs_refresh) {
                        // If data needs refresh, fetch from Google API and save
                        fetchGoogleVenueDetails(placeId, null, true);
                    } else {
                        // If data is up to date, just display it
                        displayVenueDetails(response.data);
                        showVenueSheet();
                    }
                } else {
                    console.log('Venue not found in DB, fetching from Google API and saving');
                    fetchGoogleVenueDetails(placeId, null, true);
                }
            },
            error: function (xhr, status, error) {
                console.error('Error checking venue in DB:', error);
                // If there's an error, still try to fetch and display the venue details
                fetchGoogleVenueDetails(placeId, null, true);
            }
        });
    } else {
        console.error('Place ID not found for the clicked venue');
    }
}

