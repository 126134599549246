const modalTriggers = document.querySelectorAll('[modal-target]');
const closeButtons = document.querySelectorAll('.close-modal');

let activeSheet = null;
let startY = 0;
let currentY = 0;
let isDragging = false;
const dismissThreshold = 0.3;  // 30% of sheet height
const dragThreshold = 10;  // Minimum drag distance to start dismissing

function setSheetPosition(sheet, position) {
    sheet.classList.remove('modal-bottom', 'modal-hidden');
    sheet.classList.add(`modal-${position}`);
}

function closeAllSheets() {
    document.querySelectorAll('.bot-modal').forEach(sheet => {
        if (sheet.classList.contains('modal-bottom')) {
            closeSheet(sheet);
        }
    });
}

function showSheet(sheet) {
    closeAllSheets();  // Close any open sheets before showing a new one
    sheet.style.display = 'block';
    void sheet.offsetWidth; // Force reflow
    setSheetPosition(sheet, 'bottom');
    sheet.style.setProperty('--drag-offset', '0px');
    activeSheet = sheet;
}

function closeSheet(sheet) {
    setSheetPosition(sheet, 'hidden');
    sheet.style.setProperty('--drag-offset', '0px');
    if (activeSheet === sheet) {
        activeSheet = null;
    }
}

function handleTouchStart(e) {
    if (!activeSheet) return;
    startY = e.touches[0].clientY;
    isDragging = false;
}

function handleTouchMove(e) {
    if (!activeSheet || !activeSheet.classList.contains('modal-bottom')) return;
    currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;

    if (deltaY > dragThreshold) {
        isDragging = true;
        e.preventDefault();
        activeSheet.style.setProperty('--drag-offset', `${deltaY}px`);
    }
}

function handleTouchEnd() {
    if (!activeSheet || !isDragging) return;
    
    const dragOffset = parseFloat(getComputedStyle(activeSheet).getPropertyValue('--drag-offset') || '0');
    
    if (dragOffset > activeSheet.offsetHeight * dismissThreshold) {
        closeSheet(activeSheet);
    } else {
        animateSheet(activeSheet, 'bottom');
    }
    
    isDragging = false;
}

function animateSheet(sheet, position) {
    sheet.classList.add('modal-animating');
    setSheetPosition(sheet, position);
    sheet.style.setProperty('--drag-offset', '0px');
    
    setTimeout(() => {
        sheet.classList.remove('modal-animating');
    }, 300); // Match this with your transition duration
}

modalTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
        const targetId = trigger.getAttribute('modal-target');
        const targetSheet = document.getElementById(targetId);
        if (targetSheet) {
            showSheet(targetSheet);
        }
    });
});

closeButtons.forEach(button => {
    button.addEventListener('click', () => {
        if (activeSheet) {
            closeSheet(activeSheet);
        }
    });
});

document.addEventListener('touchstart', handleTouchStart, { passive: false });
document.addEventListener('touchmove', handleTouchMove, { passive: false });
document.addEventListener('touchend', handleTouchEnd);