$(document).ready(function () {
    function changeTab(selectedTab, tabsPanel) {
        // Remove active class from all tabs
        $('.responsive-tabs li').removeClass('active');
        // Hide all tab panels
        $('.responsive-tabs-panel').stop().fadeOut(500, function () {
            $(this).removeClass('active');
        });

        // Add active class to selected tab
        $(selectedTab).parent('li').addClass('active');

        // Fade in the selected tab panel
        $(tabsPanel).stop().fadeIn(500, function () {
            $(this).addClass('active');
        });

        // Update URL without reloading the page
        if (history.pushState) {
            history.pushState(null, null, $(selectedTab).attr('href'));
        }
    }

    $('.responsive-tabs a').click(function (e) {
        e.preventDefault();
        var selectedTab = this;
        var tabsPanel = $(this).attr('href');
        changeTab(selectedTab, tabsPanel);
        
    });

    var hash = window.location.hash;
    var firstTab = hash ? $('.responsive-tabs a[href="' + hash + '"]') : $('.responsive-tabs li:first-child a');
    var firstTabPanel = $(firstTab).attr('href');
    changeTab(firstTab, firstTabPanel);

    $(window).on('hashchange', function () {
        var hash = window.location.hash;
        var currentTab = $('.responsive-tabs a[href="' + hash + '"]');
        if (currentTab.length) {
            changeTab(currentTab, hash);
        }
    });
});
